import React, { useEffect, useState, useContext } from 'react';
import Button from '../Button';

import { StageContext } from '../../contexts/StageContext';
import './StageJoin.css';


export default function JoinStage() {
    // This is for development purposes. It checks to see if we have a valid token saved in the session storage
    const cachedStageToken = sessionStorage.getItem('stage-token');
    const [stageToken, setStageToken] = useState(cachedStageToken || '');
    const { joinStage, stageJoined, leaveStage } = useContext(StageContext);

    function joinOrLeaveStage() {
        if (stageJoined) {
            leaveStage();
        } else {
            joinStage(stageToken);
        }
    }

    return (
        <div className="stage-join">
            <div>
                <label htmlFor="token">토큰</label>
                <input
                    type="text"
                    value={stageToken}
                    onChange={(e) => setStageToken(e.target.value)}
                    id="token"
                    name="token"
                />
            </div>
            <div>
                <Button onClick={joinOrLeaveStage}>{stageJoined ? '퇴장' : '입장'}</Button>
            </div>
        </div>

    );
}
