
import React from 'react';
import JoinStage from '../StageJoin';
import StageParticipants from '../StageParticipants';
import StageProvider from '../../contexts/StageContext';

function Live() {
  return (
    <div>
      <StageProvider>
        <StageParticipants />
        <JoinStage />
      </StageProvider>
    </div>
  );
}

export default Live;